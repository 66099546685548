.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 10%;
}

.my-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-btns > a {
  margin-top: 10px;
  width: 100%;
}

.img-me {
  float: right;
  max-width: 40vh;
  opacity: .8;
  margin-left: 1em;
  margin-bottom: 4em;
}

.arrow-nav {
  position: absolute;
  bottom: .5em;
}

#bio {
  font-size: 0;
}